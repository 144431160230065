import * as React from 'react';
import { FC, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { display: 'inline-block', marginTop: '1em', zIndex: 2 },
    content: { padding: 0, '&:last-child': { padding: 0 } },
    img: {
        width: 'initial',
        height: 'initial',
        maxWidth: '100%',
        maxHeight: '100%',
    },
});

interface ImageInputProps {
    record?: any;
    source?: any;
}

const IdeaImageInput: FC<ImageInputProps> = (props) => {
    const classes = useStyles();
    const [imgSrc, setImgSrc] = useState<string>("");
    
    if (!props.record) return null;
    
    let image = props.record

   
    if(typeof image !== 'string') {
        image = props.record.image
    }
   

    if (props.record.undefined && imgSrc === "") {
       
        setImgSrc(props.record.undefined);
    } else if (typeof image === 'string' && imgSrc === "") {
       
        const token = localStorage.getItem('token');
        fetch(image, {
            method: "GET",
        }).then((response: any) => {
            
            return response.blob();
        })
            .then(function (myBlob) {
                const objectURL = URL.createObjectURL(myBlob);
                setImgSrc(objectURL);
            });
    }


    

    return (
        <Card className={classes.root}>
            <CardContent className={classes.content}>
                <img src={imgSrc} alt="" className={classes.img} />
            </CardContent>
        </Card>
    );
};

export default IdeaImageInput;
