import * as React from 'react';
import { FC } from 'react';
import RichTextInput from 'ra-input-rich-text';
import IdeaImageInput from './IdeaImageInput'
import IdeaPlayer from './IdeaPlayer'
import {    
    Edit,
    SimpleForm,
    TextInput,
    required,
    maxLength,
    minLength,
    SelectInput,
    FileInput,
    BooleanInput,
    RadioButtonGroupInput
    
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import { EditComponentProps } from '../types';

import {category} from '.'


const IdeaEdit: FC<EditComponentProps> =  ({ permissions, ...props }) => {
   
    
    return (
        <Edit {...props}>
            <SimpleForm warnWhenUnsavedChanges>                
                <TextInput autoFocus source="title" validate={required()} />
                <RadioButtonGroupInput source="status" label="Status" choices={[
                        { id: 'proposal', name: 'Idea' },
                        { id: 'project', name: 'Prototype' },
                        { id: 'alpha', name: 'Alpha' },
                        { id: 'beta', name: 'Beta' },
                        { id: 'archive', name: 'Archive' },
                ]} />
                <SelectInput source="category" validate={required()} choices={category} />
                <RichTextInput source="description" validate={[required()]} />
                               
                <FileInput source="images" label="Related pictures" accept="image/*" multiple={true} >
                    <IdeaImageInput />
                </FileInput>
                
            </SimpleForm>
        </Edit>
    );
};

export default IdeaEdit;
