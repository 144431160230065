import IdeaIcon from '@material-ui/icons/EmojiObjectsOutlined';
import GavelIcon from '@material-ui/icons/Gavel';
import ArchiveIcon from '@material-ui/icons/Archive';
import BuildIcone from '@material-ui/icons/Build';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';

import IdeaList from './IdeaList';
import IdeaCreate from './IdeaCreate';
import IdeaEdit from './IdeaEdit';
import IdeaShow from './IdeaShow';

export default {
    create: IdeaCreate,
    edit: IdeaEdit,
    list: IdeaList,
    show: IdeaShow,
    icon: IdeaIcon,
};

export const projectIdeas = {    
    edit: IdeaEdit,
    list: IdeaList,
    show: IdeaShow,
    icon: GavelIcon,
};

export const alphaIdeas = {    
    edit: IdeaEdit,
    list: IdeaList,
    show: IdeaShow,
    icon: BuildIcone,
};

export const betaIdeas = {    
    edit: IdeaEdit,
    list: IdeaList,
    show: IdeaShow,
    icon: FlightTakeoff,
};

export const archiveIdeas = {    
    edit: IdeaEdit,
    list: IdeaList,
    show: IdeaShow,
    icon: ArchiveIcon,
};

export const category = [
    { id: 'trivia', name: 'Trivia' },
    { id: 'hypercasual', name: 'Hypercasual (HC)' },
    { id: 'triviahc', name: 'Trivia HC' },
    { id: 'hctrivia', name: 'HC Trivia' },
    { id: 'other', name: 'Other' },
]