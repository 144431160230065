import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import './App.css';

import authProvider from './authProvider';
import dataProvider from './dataProvider';

import { Login } from './layout';


import englishMessages from './i18n/en';

import comments from './comments';
import ideas from './ideas';
import {projectIdeas, archiveIdeas, alphaIdeas, betaIdeas} from './ideas';



const i18nProvider = polyglotI18nProvider(locale => {
    // if (locale === 'fr') {
    //     return import('./i18n/fr').then(messages => messages.default);
    // }
    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
       

    return (
        <Admin
            title="Ideabox"
            dataProvider={dataProvider}            
            authProvider={authProvider}
            loginPage={Login}
            i18nProvider={i18nProvider}
        >            
            <Resource name="ideas" label="Ideas" {...ideas} />
            <Resource name="projects" options={{ label: 'Prototypes' }} {...projectIdeas} />
            <Resource name="alphas" label="Alphas" {...alphaIdeas} />
            <Resource name="betas" label="Betas" {...betaIdeas} />
            <Resource name="archives" label="Archives" {...archiveIdeas} />
            <Resource name="comments" label="Comments" {...comments} />
        </Admin>
    );
};

export default App;