import * as React from 'react';
import { FC } from 'react';
import {
    List,
    TextField,
    DateField,
    Filter,
    DateInput,    
    EditButton,
    ShowButton,
    useListContext,
    usePermissions,
    TopToolbar,
    CreateButton,
    NullableBooleanInput
} from 'react-admin';
import { makeStyles, Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { InputProps, linkToRecord, Record } from 'ra-core';
import { Idea , FilterProps} from '../types';

import ThumbRatingInput from './ThumbRatingInput';
import ThumbRatingField from './ThumbRatingField';

import { Card, CardActions, CardContent, CardHeader } from '@material-ui/core';
import IdeaCategoryField from './IdeaCategoryField';
import ImagesField from './ImagesField';



const useStyles = makeStyles({
    cardTitle : {
        fontSize: 20
    },
    cardContent: { 
        minHeight: 200,
        maxHeight: 200,
        overflow: 'hidden'
    },
    rtField : {
        minHeight: 200,
        maxHeight: 200 
    },
    card: {
        width: 300,
        minHeight: 300,
        margin: '0.5em',
        display: 'inline-block',
        verticalAlign: 'top'
    },
    chip : {
         margin: 1 ,
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit'
    }
    
});


const isAuthor = (record:Idea) => {
    const userId= localStorage.getItem('userId');
    return record.user.id === userId
}


const QuickFilter:FC<InputProps> = ({ label }) => {
    const classes = useStyles();
    return <Chip className={classes.chip} label={label} />;
};




const ListFilters = (props: any) => {
    
    return (
    <Filter {...props}>
        <DateInput source="date_gte" alwaysOn />
        <DateInput source="date_lte" alwaysOn />   
        <NullableBooleanInput source="voted" />       
    </Filter>
)};


const IdeaGrid = () => {
    const { ids, data, basePath } = useListContext();
    const {permissions} = usePermissions();
    const classes = useStyles();


    return (
        <div style={{ margin: '1em' }}>
        {ids.map((id:any) =>            
            
            <Card key={id} className={classes.card}>
                <Link className={classes.link} to={basePath+'/'+id+'/'+'show'}>
                <CardHeader
                    title={<><TextField className={classes.cardTitle} record={data[id]} source="title" /><IdeaCategoryField record={data[id]} source="category" /></>}
                    subheader={<><DateField record={data[id]} source="createdAt" /> by <TextField record={data[id]} source="user.name" /></>}                    
                />
                </Link>
                <CardContent className={classes.cardContent}>
                    <ThumbRatingInput record={data[id]} source="ratings" />
                    {permissions === 'admin' && <ThumbRatingField record={data[id]} label="All ratings" source="ratings" global/> }
                    <Link className={classes.link} to={basePath+'/'+id+'/'+'show'}><ImagesField record={data[id]} source="images" /></Link>
                </CardContent>
                <CardActions style={{ textAlign: 'right', backgroundColor: "#FFF" }}>
                    {(isAuthor(data[id]) || permissions === 'admin') && <EditButton resource="ideas" basePath={basePath} record={data[id]} />}
                    <ShowButton resource="ideas" basePath={basePath} record={data[id]}  />
                </CardActions>
            </Card>
            
        )}
        </div>
    );
};

const IdeaList = ({permissions, ...props}:any) => {

    if (props.resource === 'projects') {
        return (<List {...props} filters={<ListFilters />} perPage={25} filterDefaultValues={{status : 'project'}} sort={{ field: 'createdAt', order: 'DESC' }}>
        <IdeaGrid />
        </List>)
    } else if (props.resource === 'archives') {
        return (<List {...props} filters={<ListFilters />} perPage={25} filterDefaultValues={{status : 'archive'}} sort={{ field: 'createdAt', order: 'DESC' }}>
        <IdeaGrid />
        </List>)
    } else if (props.resource === 'alphas') {
        return (<List {...props} filters={<ListFilters />} perPage={25} filterDefaultValues={{status : 'alpha'}} sort={{ field: 'createdAt', order: 'DESC' }}>
        <IdeaGrid />
        </List>)
    } else if (props.resource === 'betas') {
        return (<List {...props} filters={<ListFilters />} perPage={25} filterDefaultValues={{status : 'beta'}} sort={{ field: 'createdAt', order: 'DESC' }}>
        <IdeaGrid />
        </List>)
    } else {
        return (<List {...props} exporter={false} filters={<ListFilters />} filterDefaultValues={{status : 'proposal'}} perPage={25} sort={{ field: 'createdAt', order: 'DESC' }}>
        <IdeaGrid />
        </List>)
    }
};


export default IdeaList;
