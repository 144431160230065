import React from 'react';
import { FC } from 'react';
import {
  DateField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  RichTextField
} from 'react-admin';
import { ShowComponentProps, Comment } from '../types';

const CommentShow: FC<ShowComponentProps> = props => (
  <Show {...props}>
    <SimpleShowLayout>      
      <ReferenceField source="idea_id" reference="ideas" link="show"> 
        <TextField source="title" />
      </ReferenceField>
      <TextField source="user.name" label="User"/>
      <DateField source="createdAt" />
      <RichTextField source="content" label="Comment"/>
    </SimpleShowLayout>
  </Show>
);

export default CommentShow;
