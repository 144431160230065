import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FieldProps, Idea } from '../types';
import {category} from '.'
import { Chip } from '@material-ui/core';



const useStyles = makeStyles({
 
    chip : {
         margin: 1,
    }
});


const IdeaCategoryField: FC<FieldProps<Idea>> = ({ record }) => {
    const classes = useStyles();
    return record && record.category ? (
        <>
            <Chip
                        size="small"
                        key={record.id}
                        className={classes.chip}
                        label={category.find(cat => record.category === cat.id)?.name}
            />
        </>
    ) : null;
};


export default IdeaCategoryField;


