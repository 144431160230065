import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { FieldProps , Idea} from '../types';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles({
  root: {
    width: 340,
    display: 'flex',
    alignItems: 'center',
  },
  nbvotes: {
    fontSize: '10px'
  },
  notVoted : {
    color: '#585858'    
  }, 
  liked : {
    color: '#00FF00'    
  },
  disliked : {
    color: '#FF0000'    
  }
});

const getTotalUpRating = (ratings:any)  =>
  ratings.filter((current:any) => current.rating>=3).length

const getUserRating = (ratings:any) => {
  const userId= localStorage.getItem('userId');
  let rating = userId && ratings && ratings.find(function(currentValue:any, index:any) {
      if(currentValue.user === userId) return true
  });
  
  if (rating) 
      return rating.rating
  else 
      return -1
}


interface Props extends FieldProps<Idea> {
  global?: boolean
}

const ThumbRatingField: FC<Props> = ({global, ...props}) => {
    

  const classes = useStyles();
  let totalUpRating = getTotalUpRating(props?.record?.ratings)
  let totalDownRating = props?.record?.ratings.length - totalUpRating
  let userRating = getUserRating(props?.record?.ratings)

  

  return (
    <div className={classes.root}>
      <Box>Total rating : </Box>
      <IconButton disabled={true}>
      <Badge badgeContent={userRating>=0?totalUpRating:'-'} className={totalUpRating>totalDownRating?classes.liked:classes.notVoted}>
        <ThumbUpIcon />
      </Badge>
      </IconButton>
      <IconButton disabled={true}>
      <Badge badgeContent={userRating>=0?totalDownRating:'-'} className={totalDownRating>totalUpRating?classes.disliked:classes.notVoted}>
        <ThumbDownIcon />
      </Badge>
      </IconButton>
    </div>
  );
}

export default ThumbRatingField;
