import * as React from 'react';
import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { FieldProps , Idea} from '../types';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: 'initial',
    height: 'initial',
    maxWidth: '100%',
    maxHeight: '100%',
  },
});


interface Props extends FieldProps<Idea> {
  global?: boolean
}

const ImagesField: FC<Props> = ({global, ...props}) => {
    

  const classes = useStyles();
 
  return (
    <div className={classes.root}>
      {props?.record?.images.map((img:string) => {
          return (<Paper elevation={3} className={classes.root}><img src={img} alt="" className={classes.img} /></Paper>) 
      })}
    </div>
  );
}

export default ImagesField;
