import React from 'react';
import { FC } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  TextInput
} from 'react-admin';
import { parse } from 'query-string';
import { EditComponentProps } from '../types';

const today = new Date();

const CommentEdit: FC<EditComponentProps> = (props) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  
  const { idea_id } = parse(props.location.search);

  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a post_id
  // from the url by returning an empty string if post_id isn't specified
  
 

  const redirect = idea_id ? `/ideas/${idea_id}/show/comments` : false;

  return (
    <Edit {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        redirect={redirect}
      >
        <ReferenceInput
          source="idea_id"
          reference="ideas"
          validate={required()}
          defaultValue={idea_id}
          disabled
        >
          <SelectInput optionText="title" />
        </ReferenceInput>
        <RichTextInput source="content" />
      </SimpleForm>
    </Edit>
  );
};

export default CommentEdit;
