import * as React from "react";
import { FC } from 'react';
import { Show, useGetList, Tab, Datagrid, ReferenceManyField, TabbedShowLayout, RichTextField, TextField, DateField, TopToolbar, EditButton} from 'react-admin';

import ThumbRatingInput from './ThumbRatingInput';
import ImagesField from './ImagesField';
import IdeaCategoryField from './IdeaCategoryField';
import { makeStyles } from '@material-ui/core/styles';
import ThumbRatingField from "./ThumbRatingField";
import {ToolbarProps, Idea, Comment} from '../types';
import CommentAddButton from '../comments/CommentAddButton';
import { userCheck } from "ra-core";

const useStyles = makeStyles({
    ideaTitle : {
        fontSize: 30
    },
})

const isAuthor = (record:Comment) => {
    const userId= localStorage.getItem('userId');
    return record.user.id === userId
  }
  
  
const postRowClick = (id:any, basePath:any, record:any) => {
return isAuthor(record) ? 'edit' : 'show'
};

const IdeaShowActions:FC<ToolbarProps<Idea>> = ({ basePath, resource,  data, permissions }) => (
    <TopToolbar>
        {permissions === 'admin' &&  <EditButton basePath={basePath} resource={resource} record={data} />}
    </TopToolbar>
);

const IdeaShow = ({permissions, ...props}:any) => {
    const classes = useStyles();
    const { total } = useGetList('comments',{ perPage: 1, page: 1 }, { field: 'id', order: 'ASC' }, {idea_id: props.id});
    return (<Show actions={<IdeaShowActions permissions={permissions} />} {...props}>
         <TabbedShowLayout>
            <Tab label="Details">
            <TextField className={classes.ideaTitle} source="title" /><IdeaCategoryField  source="category" />   
            <DateField label="Publication date" source="createdAt" />            
            <ThumbRatingInput source="ratings" />
            {permissions === 'admin' && <ThumbRatingField source="ratings" global/>}            
            <RichTextField source="description" />            
            <ImagesField source="images"/>
            </Tab>
            <Tab label={"Comments ("+total+")"} path="comments">
                <ReferenceManyField
                addLabel={false}
                reference="comments"
                target="idea_id"
                sort={{ field: 'createdAt', order: 'DESC' }}
                >
                <Datagrid rowClick={postRowClick}>       
                    <DateField source="createdAt" />             
                    <TextField source="user.name" label="User" />
                    <RichTextField source="content" lable="Comment"/>
                </Datagrid>
                </ReferenceManyField>
                <CommentAddButton />
            </Tab>
            
                        
        </TabbedShowLayout>
    </Show>
)};

// const { total: totalOrdered } = useGetList(
//     'commands',
//     { perPage: 1, page: 1 },
//     { field: 'id', order: 'ASC' },
//     { ...filterValues, status: 'ordered' }
// );

export default IdeaShow