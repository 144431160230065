import CommentIcon from '@material-ui/icons/Comment';

import CommentList from './CommentList'
import CommentCreate from './CommentCreate';
import CommentEdit from './CommentEdit';
import CommentShow from './CommentShow';

export default {
    create: CommentCreate,
    edit: CommentEdit,
    list: CommentList,
    show: CommentShow,
    icon: CommentIcon,
};
