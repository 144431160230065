import React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  Responsive,
  RichTextField,
  SimpleList,
  TextField,
  useListContext
} from 'react-admin';
import { ListComponentProps, Comment } from '../types';




const isAuthor = (record:Comment) => {
  const userId= localStorage.getItem('userId');
  return record.user.id === userId
}

const postRowClick = (id:any, basePath:any, record:any) => {
  return isAuthor(record) ? 'edit' : 'show'
};

const CommentList: FC<ListComponentProps> = (props) => {
  const { ids, data, basePath } = useListContext();
  return (
  <List {...props}>
    <Responsive
      small={
        <SimpleList
          linkType="show"
          primaryText={(record:Comment) => record.body}
          tertiaryText={(record:Comment) =>
            new Date(record.created_at).toLocaleDateString()
          }
        />
      }
      medium={
        <Datagrid rowClick={postRowClick}>
          <DateField source="createdAt" />
          <TextField source="user.name" label="User"/>
          <RichTextField source="content" lable="Comment"/>
          <ReferenceField
            resource="comments"
            source="idea_id"
            reference="ideas"
            link="show"
          >
          <TextField source="title" label="Idea" />
          </ReferenceField>
        </Datagrid>
      }
    />
  </List>
  )
};

export default CommentList;
