import * as React from 'react';
import { FC , useState, useEffect} from 'react';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { makeStyles } from '@material-ui/core/styles';
import { InputProps , Idea} from '../types';
import { useNotify, useDataProvider, useRedirect } from 'react-admin';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';



const useStyles = makeStyles({
  root: {
    width: 400,
    display: 'flex',
    alignItems: 'center',
  },
  notVoted : {
    color: '#585858'    
  }, 
  liked : {
    color: '#00FF00'    
  },
  disliked : {
    color: '#FF0000'    
  }
  
});

const getUserRating = (ratings:any) => {
  const userId= localStorage.getItem('userId');
  let rating = userId && ratings && ratings.find(function(currentValue:any, index:any) {
      if(currentValue.user === userId) return true
  });
  
  if (rating) 
      return rating.rating
  else 
      return -1
}


const ThumbRatingInput: FC<{ record?: Idea, source:string }> = ({ record }) => {
  
  const userId= localStorage.getItem('userId');
  
  //let userRating = getUserRating(record?.ratings)
  const classes = useStyles();
  const notify = useNotify();
  const redirectTo = useRedirect();  
  const dataProvider= useDataProvider({undoable:false});
  const [userRating, setUserRating] = useState(-1);
  useEffect(() => {    
    userRating == -1 && dataProvider.getOne('ideas', {id: record?.id}).then((r:any)=>{
      setUserRating(getUserRating(r?.data?.ratings))
    })    
  },[]);
  
  
  const onChangeRating = (like:boolean) => (event: React.MouseEvent<{}>) => {
    
    return dataProvider.updateRatings('ideas', { 
    id: record?.id, 
    data: { 
        user: userId,
        rating: like?5:0
    } 
    }).then((response:any) => {
                notify(
                    'Thanks for your review',
                    'info',
                    {},
                    true
                );
                setUserRating(like?5:0)
                redirectTo('/ideas');
    }).catch((error:any) => 
                notify(
                    'Error while reviewing',
                    'warning'
                )
    );
  }
  
  return (
    <div className={classes.root}>
      <Box>Your rating : </Box>
      <IconButton className={userRating>3?classes.liked:classes.notVoted} onClickCapture={onChangeRating(true)}>
        <ThumbUpIcon />
      </IconButton>
      <IconButton className={(userRating>=0 && userRating<=3)?classes.disliked:classes.notVoted} onClickCapture={onChangeRating(false)}>
        <ThumbDownIcon />
      </IconButton>
     
    </div>
  );
}

export default ThumbRatingInput;

{/* <Rating
name="ratings"
precision={0.5}
onChange={onChangeRating}
size="medium"
value={value?.rating}
/> */}