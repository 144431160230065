import * as React from 'react';
import { FC, useMemo } from 'react';
import RichTextInput from 'ra-input-rich-text';
import {    
    Create,    
    NumberInput,    
    SaveButton,    
    SimpleForm,
    TextInput,
    Toolbar,
    required,
    SelectInput,
    FileInput
} from 'react-admin'; // eslint-disable-line import/no-unresolved
import IdeaImageInput from './IdeaImageInput'
import IdeaPlayer from './IdeaPlayer'
import { CreateComponentProps } from '../types';



const IdeaCreate: FC<CreateComponentProps> = ({ permissions, ...props }) => {
    const initialValues = useMemo(
        () => ({
            //average_note: 0,
        }),
        []
    );

    const dateDefaultValue = useMemo(() => new Date(), []);

    return (
        <Create {...props}>
            <SimpleForm
                initialValues={initialValues} 
                warnWhenUnsavedChanges
            >
                             
                            <TextInput autoFocus source="title" validate={required()} />
                            <SelectInput source="category" validate={required()} choices={[
                                    { id: 'trivia', name: 'Trivia' },
                                    { id: 'hypercasual', name: 'Hypercasual (HC)' },
                                    { id: 'triviahc', name: 'Trivia HC' },
                                    { id: 'hctrivia', name: 'HC Trivia' },
                                    { id: 'other', name: 'Other' },
                            ]} />
                            <RichTextInput source="description" validate={required()} />
                                                       
                            <FileInput source="images" label="Related pictures" accept="image/*" multiple={true}>
                                <IdeaImageInput />
                            </FileInput>
                            

            </SimpleForm>
        </Create>
    );
};

export default IdeaCreate;
