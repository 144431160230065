import { stringify } from 'query-string';
import { fetchUtils, DataProvider } from 'ra-core';


/**
 * Maps react-admin queries to a simple REST API
 *
 * This REST dialect is similar to the one of FakeRest
 *
 * @see https://github.com/marmelab/FakeRest
 *
 * @example
 *
 * getList     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * getOne      => GET http://my.api.url/posts/123
 * getMany     => GET http://my.api.url/posts?filter={id:[123,456,789]}
 * update      => PUT http://my.api.url/posts/123
 * create      => POST http://my.api.url/posts
 * delete      => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import simpleRestProvider from 'ra-data-simple-rest';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={simpleRestProvider('http://path.to.my.api/')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */

// const handleFileDownload = async (apiUrl:string, record:Idea) {

//     const token = localStorage.getItem('token');

//     if(record.image) {
//         await fetch(apiUrl+'/files', {
//             method: 'GET',
//             headers: {
//                 // Content-Type may need to be completely **omitted**
//                 // or you may need something
//                 "Authorization": `Bearer ${token}`,
//             }
//         }).then(
//             response => response.json() // if the response is a JSON object
//         ).then(
//             success => record.image = success.data
//         ).catch(
//             error => console.log(error) // Handle the error response object
//     }

// }

const handleFileUpload = async (apiUrl:string, params:any) => {

    const token = localStorage.getItem('token');
    
    let fileImage:any
    

    if(params?.data?.pitch?.rawFile )  {
        let fd = new FormData();
        fd.append('file',params.data.pitch.rawFile)
        await fetch(apiUrl+'/upload', { // Your POST endpoint
            method: 'POST',
            headers: {
                // Content-Type may need to be completely **omitted**
                // or you may need something
                "Authorization": `Bearer ${token}`,
            },
            body: fd // This is your file object
            }).then(
                response => response.json() // if the response is a JSON object
            ).then(
                success => params.data.pitch = apiUrl+'/files/'+success.data.name
            ).catch(
                error => console.log(error) // Handle the error response object
        );
    }
    if(params?.data?.images && params?.data?.images.length > 0 && params?.data?.images[0].rawFile)  {
            
                
                let fd = new FormData();
                
                params.data.images.forEach((rf:any) => {
                    fd.append('files',rf.rawFile) 
                });

                

                await fetch(apiUrl+'/upload', { // Your POST endpoint
                    method: 'POST',
                    headers: {
                        // Content-Type may need to be completely **omitted**
                        // or you may need something
                        "Authorization": `Bearer ${token}`,
                    },
                    body: fd // This is your file object
                    }).then(
                        response => response.json() // if the response is a JSON object
                    ).then(
                        success => params.data.images = success.data.names.map((name:any) => apiUrl+'/files/'+name)
                    ).catch(
                        error => console.log(error) // Handle the error response object
                );

            
          
    }

    return params
}

export default (
    apiUrl: string,
    httpClient = fetchUtils.fetchJson,
    countHeader: string = 'Content-Range'
): DataProvider => ({
    getList: (resource, params) => {
        
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            page: JSON.stringify(page),
            limit: JSON.stringify(perPage),
            sort: order==='DESC'?"-".concat(field):field,
            ...params.filter
        };

        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.rows, total:  json.count}));        
    },
    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => { 

            return {
                data: json,
            }
        }),
    getMany: (resource, params) => {
        const query = {
            ids: params.ids.toString(),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.rows, total:  json.count}));
    },
    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const query = {
            page: JSON.stringify(page),
            limit: JSON.stringify(perPage),
            sort: order==='DESC'?"-".concat(field):field,
            ...params.filter,
            [params.target]: params.id
        };
        
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.rows, total:  json.count}));
    },
    update: async (resource, params) => {
        await handleFileUpload(apiUrl, params)
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },
    updateRatings: async (resource:string, params:any) => {
        return httpClient(`${apiUrl}/${resource}/${params.id}/ratings`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }))
    },
    // simple-rest doesn't handle provide an updateMany route, so we fallback to calling update n times instead
    updateMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'PUT',
                    body: JSON.stringify(params.data),
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
    create: async (resource, params) =>{
        
        await handleFileUpload(apiUrl, params)
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST',
            body: JSON.stringify(params.data),
        }).then(({ json }) => {
            return ({
            data: { ...params.data, id: json.id },
        })})
    },
    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: json })),

    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    deleteMany: (resource, params) =>
        Promise.all(
            params.ids.map(id =>
                httpClient(`${apiUrl}/${resource}/${id}`, {
                    method: 'DELETE',
                })
            )
        ).then(responses => ({ data: responses.map(({ json }) => json.id) })),
});