import React from "react";
import { FC } from 'react';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Idea} from '../types';


const styles = {
    button: {
      marginTop: '1em'
    }
  };

const AddNewCommentButton : FC<Idea> = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: '/comments/create',
            search: `?idea_id=${record.id}`
        }}
        label="Add a comment"
    >
        <ChatBubbleIcon />
    </Button>
);


export default withStyles(styles)(AddNewCommentButton);